@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./typography.css";
@import "./tooltip.css";
@import "./chart.css";
@import "./custom.css";
@import "keen-slider/keen-slider.min.css";

@layer base {
	html {
		scroll-behavior: smooth;
	}

	body {
		@apply relative box-border h-full touch-manipulation overflow-x-hidden overscroll-x-none bg-main font-sans;
		font-feature-settings: "rlig" 1, "calt" 1;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	input,
	select,
	textarea {
		@apply rounded-md border-gray-400 hover:shadow-input-hover focus:border-primary focus:shadow-sm focus:outline-none focus:ring-0;
	}

	button {
		@apply focus:outline-none;
	}

	/* TYPOGRAPHY */
	h1 {
		@apply text-36 lg:text-5xl xl:text-60;
	}

	h2 {
		@apply text-20 lg:text-4xl xl:text-40;
	}

	h3 {
		@apply text-16 lg:text-30;
	}

	h4 {
		@apply text-xl lg:text-25;
	}

	h5 {
		@apply text-lg lg:text-20;
	}

	h6 {
		@apply text-base;
	}

	sup {
		@apply text-xs font-semibold leading-4;
	}
}

@layer utilities {
	.text-stroke {
		-webkit-text-stroke: unset;
	}

	.text-stroke-md {
		-webkit-text-stroke: 0.6px currentColor;
	}

	.isActive-link {
		@apply text-stroke bg-examine-purple-100 text-primary;
	}
}

/* accomodate some classes used in markdown */
.bg-blue {
	background-color: #ebfcff;
}

.bg-green {
	background-color: #e0edcc;
	color: #222;
}

.bg-yellow {
	background-color: #ffe6b9;
	color: #222;
}

.bg-red {
	background-color: #fbd4d9;
	color: #222;
}